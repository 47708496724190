import { AxiosResponse } from 'axios'
import { split } from 'lodash-es'
import { getSFPackageVersion } from '../../../queries/api/getSFPackageVersion'
import { FileUploadResponse } from '../../../types'
import { ApiErrorState, setApiErrorAction } from '../../apiError'
import { AppThunk, getApiClient4Thunks, TDispatch, TGetState } from '../../index'
import { setSFVersion, updateCurrentProjectAction, updateLoadingNextStep } from '../actions'

export const retrieveThirdPartyIntegrationSampleCSVFileAndUpload =
	(): AppThunk<Promise<void>> =>
	(dispatch: TDispatch, getState: TGetState): Promise<void> => {
		const apiClient = getApiClient4Thunks(dispatch)
		const thirdPartyIntegration = getState().projectWizard.currentProject.thirdPartyIntegration
		const selectedConnection = thirdPartyIntegration?.selectedOrgId
		const orgid = selectedConnection ? selectedConnection : getState().session.orgid
		const platform = selectedConnection ? 'salesforce' : getState().session.platform
		const sourceType = thirdPartyIntegration ? thirdPartyIntegration.crmSource : ''
		const url = `/pls/integration/${platform}/organization/${orgid}/csv/${sourceType}/sample`
		dispatch(updateLoadingNextStep(true))
		return apiClient
			.get(url)
			.then((response: AxiosResponse<string>) => {
				const blob = new Blob([response.data], { type: 'text/csv' })
				const fileName = `${platform}-${orgid}-${sourceType}`
				const file = new File([blob], `${fileName}.csv`, { type: 'text/csv' })
				const fileUploadURL = `/pls/fileuploads/${fileName}`
				const formData = new FormData()
				formData.append('file', file)
				const config = {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}
				getSFPackageVersion(apiClient, platform, orgid, undefined).then((versionResponse) => {
					if (versionResponse.version) {
						const versionSplit = split(versionResponse.version, '.')
						if (versionSplit[0] === '0') {
							const additionalInfo = ''
							const errorCode = 'LEDP_90003'

							const errorData: ApiErrorState = {
								additionalInfo,
								errorCode
							}

							dispatch(setApiErrorAction(errorData))
						}

						dispatch(setSFVersion(versionResponse.version || ''))
					}
				})
				dispatch(
					updateCurrentProjectAction({
						fileInfo: { file: file, name: fileName }
					})
				)
				return apiClient
					.post(fileUploadURL, formData, config)
					.then(async (uploadResponse: AxiosResponse<FileUploadResponse>) => {
						dispatch(updateCurrentProjectAction({ fileInfo: { uploadInfo: uploadResponse.data } }))
						dispatch(updateLoadingNextStep(false))
					})
					.catch((e) => {
						console.error(e)
						const errorMessage = JSON.parse(e.request.response).UIAction
						dispatch(updateLoadingNextStep(false))
						const errorData: ApiErrorState = {
							errorCode: errorMessage.code,
							message: errorMessage.message
						}
						dispatch(setApiErrorAction(errorData))
						throw e
					})
			})
			.catch((error) => {
				console.error(error)
				const errorMessage = JSON.parse(error.request.response).UIAction
				dispatch(updateLoadingNextStep(false))
				const errorData: ApiErrorState = {
					errorCode: errorMessage.code,
					message: errorMessage.message
				}
				dispatch(setApiErrorAction(errorData))
				throw error
			})
	}
